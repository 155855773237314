import React from "react";
import Layout from "../../components/Layout/Layout";
import LegalNav from "../../components/LegalNav/LegalNav";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";

const Content = () => {
  return (
    <>
      <p>Please choose a page from the menu</p>
    </>
  );
};

const LegalPage = () => {
  return (
    <Layout>
      <SidebarLayout nav={<LegalNav />} content={<Content />} />
    </Layout>
  );
};

export default LegalPage;
